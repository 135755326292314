import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormGroupDirective,
} from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  questions: FormGroup;
  longForm: FormGroup;

  private questionsInitialValue;

  private longFormInitialValue;

  constructor(
    private firestore: AngularFirestore,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.longForm = this.formBuilder.group({
      name: new FormControl("", [Validators.required]),
      company: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      telephone: new FormControl("", [Validators.required]),
      from: new FormControl("", [Validators.required]),
      to: new FormControl("", [Validators.required]),
      where: new FormControl("", [Validators.required]),
      responsible: new FormControl("", [Validators.required]),
      number: new FormControl("", [Validators.required]),
      text: new FormControl("", [Validators.required]),
    });
    this.longFormInitialValue = this.longForm.value;
    this.questions = this.formBuilder.group({
      name: new FormControl("", [Validators.required]),
      surname: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      text: new FormControl("", [Validators.required]),
    });
    this.questionsInitialValue = this.questions.value;
  }

  submitQuestions() {
    const { name, surname, email, text } = this.questions.value;
    const html = `
      <p>Imię i nazwisko: ${name} ${surname}</p>
      <p>Email: ${email}</p>
      <p>Wiadomość: ${text}</p>
    `;
    let formRequest = {
      to: environment.email,
      from: email,
      replyTo: email,
      message: {
        subject: `Formularz kontaktowy - ${name} ${surname}`,
        html: html,
      },
    };
    this.firestore
      .collection("mail")
      .add(formRequest)
      .then(() => {
        this.questions.reset(this.questionsInitialValue);
        Object.keys(this.questions.controls).forEach((key) => {
          this.questions.get(key).setErrors(null);
        });
        this._snackBar.open("Wiadomość została pomyślnie wysłana.", null, {
          duration: 2000,
        });
      })
      .catch(() => {
        this._snackBar.open(
          "Wystąpił błąd. Wiadomość nie została wysłana. ",
          null,
          {
            duration: 5000,
          }
        );
      });
  }

  submitLongForm() {
    const {
      name,
      company,
      email,
      telephone,
      from,
      to,
      where,
      responsible,
      number,
      text,
    } = this.longForm.value;

    const html = `
      <p>Imię i nazwisko: ${name}</p>
      <p>Firma: ${company}</p>
      <p>Numer telefonu: ${telephone}</p>
      <p>Odjazd: ${from}</p>
      <p>Powrót: ${to}</p>
      <p>Adres: ${where}</p>
      <p>Osoba odpowiedzialna: ${responsible}</p>
      <p>Liczba osób: ${number}</p>
      <p>Miejsce docelowe i plan wycieczki: ${text}</p>
    `;
    let formRequest = {
      to: environment.email,
      from: email,
      replyTo: email,
      message: {
        subject: `Zamówienie usługi - ${name}`,
        html: html,
      },
    };
    this.firestore
      .collection("mail")
      .add(formRequest)
      .then(() => {
        this.longForm.reset(this.longFormInitialValue);
        Object.keys(this.longForm.controls).forEach((key) => {
          this.longForm.get(key).setErrors(null);
        });
        this._snackBar.open("Wiadomość została pomyślnie wysłana.", null, {
          duration: 2000,
        });
      })
      .catch(() => {
        this._snackBar.open(
          "Wystąpił błąd. Wiadomość nie została wysłana. ",
          null,
          {
            duration: 5000,
          }
        );
      });
  }
}
