<section class="privacy-container">
  <h1>POLITYKA PRYWATNOŚCI STRONY INTERNETOWEJ MISTRAL</h1>
  <p>
    Dokładamy szczególnej staranności w celu ochrony Twoich danych osobowych i
    zapewniamy, że zbierane przez nas dane są przetwarzane zgodnie z prawem, w
    szczególności zgodnie z Ogólnym Rozporządzeniem o Ochronie Danych z dnia 27
    kwietnia 2016 r. („RODO”).
  </p>

  <h3>W jaki sposób pozyskujemy Twoje dane?</h3>
  <p>
    Korzystamy z Twoich danych osobowych, ponieważ zdecydowałeś się skontaktować
    z nami przez formularz “Zamów usługę” bądź “Skontaktuj się z nami” na naszej
    stronie <a routerLink="/contact">Kontakt</a>.
  </p>

  <h3>Kto jest administratorem Twoich danych osobowych?</h3>
  <p>
    Administratorem danych osobowych zbieranych za pośrednictwem strony
    internetowej www.mistral.lubin.pl jest firma P.P.H.U. "Al-L" Mistral z
    siedzibą w Lubinie (ul. Legnicka 76, 59-300 Lubin), NIP 6921032759, REGON
    390172316, adres poczty elektronicznej: mistral-bus@wp.pl, zwany dalej
    „Administratorem”.
  </p>

  <h3>Jak możesz się z nami skontaktować?</h3>
  <p>
    Możesz się z nami skontaktować pod numerem telefonu: +48 603 758 230 oraz
    mailowo: mistral-bus@wp.pl, a także listownie: P.P.H.U. "Al-L" Mistral, ul.
    Legnicka 76, 59-300 Lubin.
  </p>

  <h3>Jak przetwarzamy Twoje dane osobowe?</h3>
  <p>Mistral przetwarza dane osobowe w celach:</p>
  <ul>
    <li>
      (1) kontaktowania się z Tobą, w tym w celach związanych ze świadczeniem
      usług
    </li>
    <li>
      (2) sprzedaży serwisu oferowanego przez Mistral - podstawą prawną
      przetwarzania danych jest wykonanie umowy (art. 6 ust. 1 lit. b RODO)
    </li>
    <li>
      (3) dodatkowo, przepisy prawa wymagają od nas przetwarzania Twoich danych
      dla celów podatkowych i rachunkowych
    </li>
  </ul>

  <h3>Komu będziemy udostępniać Twoje dane osobowe?</h3>
  <ul>
    <li>
      HAZEL (VAT DK38483536) podmiot dostarczający i wspierający systemy
      teleinformatyczne MISTRAL w celu prowadzenia i obsługi strony
      www.mistral.lubin.pl oraz jej wersji mobilnej
    </li>
    <li>
      BIURO KSIĘGOWE świadczące usługi związane z bieżącą działalnością MISTRAL
    </li>
    <li>NAZWA.PL – hosting strony</li>
    <li>
      Możemy także udostępniać Twoje dane osobowe innym podmiotom z powyższych
      kategorii, z którymi nawiążemy współpracę.
    </li>
  </ul>

  <h3>Czy podanie danych osobowych jest obowiązkowe?</h3>
  <p>
    Od Ciebie zależy czy udostępnisz nam dane, aczkolwiek, musisz wiedzieć, że
    aby zrealizować Twoje zamówienie podanie niektórych danych będzie
    obowiązkowe. Nie podanie danych będzie skutkowało brakiem złożenia
    zamówienia.
  </p>

  <h3>Jak długo będziemy przetwarzać Twoje dane osobowe?</h3>
  <p>
    Dane osobowe przetwarzane będą przez okres niezbędny dla wykonania umowy, a
    po tym okresie dla celów i przez czas oraz w zakresie wymaganym przez
    przepisy prawa lub dla zabezpieczenia ewentualnych roszczeń, lub do czasu
    cofnięcia udzielonej zgody, jednak nie dłużej niż 10 lat.
  </p>

  <h3>
    Jakie masz uprawnienia wobec MISTRAL w zakresie przetwarzanych danych?
  </h3>
  <p>
    Każdej osobie, w zakresie wynikającym z przepisów prawa, przysługuje prawo
    do:
  </p>
  <ul>
    <li>dostępu do swoich danych oraz ich sprostowania</li>
    <li>usunięcia lub ograniczenia przetwarzania</li>
    <li>wniesienia sprzeciwu wobec przetwarzania</li>
    <li>przenoszenia danych</li>
    <li>cofnięcia udzielonej zgody w dowolnym momencie</li>
  </ul>
  <p>
    Cofnięcie zgody nie ma wpływu na zgodność z prawem przetwarzania, którego
    dokonano na podstawie zgody przed jej cofnięciem.
  </p>

  <h3>Jak dbamy o zabezpieczenie Twoich danych osobowych?</h3>
  <p>
    Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę
    przetwarzanych danych osobowych odpowiednią do zagrożeń oraz kategorii
    danych objętych ochroną, a w szczególności zabezpiecza dane przed ich
    udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną,
    przetwarzaniem z naruszeniem obowiązujących przepisów oraz zmianą, utratą,
    uszkodzeniem lub zniszczeniem. Administrator odpowiednio udostępnia
    następujące środki techniczne zapobiegające pozyskiwaniu i modyfikowaniu
    przez osoby nieuprawnione danych osobowych przesyłanych drogą elektroniczną:
  </p>
  <ul>
    <li>(1) Zabezpieczenie zbioru danych przed nieuprawnionym dostępem</li>
    <li>
      (2) Dostęp do Konta jedynie po podaniu indywidualnego loginu i hasła
    </li>
  </ul>

  <h3>Informacja o organie właściwym do wniesienia skargi</h3>
  <p>
    Jeżeli uważasz, że przetwarzamy Twoje dane osobowe niezgodnie z prawem,
    możesz też wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych. Jeżeli
    masz jakiekolwiek pytania związane z przetwarzaniem przez nas Twoich danych
    osobowych lub chcesz skorzystać z uprawnień wynikających z RODO, skorzystaj
    z
    <a routerLink="/contact">formularza kontaktowego</a>.
  </p>

  <h3>COOKIES I DANE EKSPLOATACYJNE</h3>
  <p>MISTRAL nie zbiera COOKIES oraz danych eksploatacyjnych.</p>
  <br />
  <br />
  <p>Opublikowano dnia 25.05.2018</p>
</section>
