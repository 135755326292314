import { Component, OnInit } from "@angular/core";
import { RouteInterface } from "../route.interface";
import { SCHEDULE } from "../schedule";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-picker",
  templateUrl: "./picker.component.html",
  styleUrls: ["./picker.component.scss"],
})
export class PickerComponent implements OnInit {
  now = `${new Date().getHours()}:${new Date().getMinutes()}`;
  routes: RouteInterface[] = SCHEDULE;
  availableTo: string[];
  minDate = new Date();
  maxDate = new Date(new Date().getFullYear(), 11, 31);
  showResult = false;

  from: { route: RouteInterface; stop: string };
  to: string;
  time = this.now;
  dateInput = this.datePipe.transform(this.minDate, "dd-MM-yyyy");
  departureTimes = [];
  arrivalTimes = [];

  constructor(private datePipe: DatePipe) {}

  ngOnInit() {}

  updateTo() {
    this.availableTo = this.from.route.stops.filter(
      (stop, index) => index > this.from.route.stops.indexOf(this.from.stop)
    );
  }

  search() {
    this.showResult = true;

    const departureIndex = this.from.route.stops.indexOf(this.from.stop);
    const arrivalIndex = this.from.route.stops.indexOf(this.to);

    this.from.route.schedules.forEach((schedule) => {
      const selectedTime = new Date().setHours(
        +this.time.split(":")[0],
        +this.time.split(":")[1]
      );

      // Find departure times
      const t = schedule.times[departureIndex];
      if (t !== "-") {
        const possibleT = new Date().setHours(
          +t.split(":")[0],
          +t.split(":")[1]
        );

        if (possibleT > selectedTime) {
          this.departureTimes.push(this.datePipe.transform(possibleT, "HH:mm"));

          const arrivalTime = schedule.times[arrivalIndex];
          this.arrivalTimes.push(arrivalTime);
        }
      }
    });
  }

  clear() {
    this.showResult = false;
  }

  changedTime(event) {
    this.time = event;
  }

  changedDate(event) {
    this.dateInput = this.datePipe.transform(event.value, "dd-MM-yyyy");
  }
}
