export const SCHEDULE = [
  {
    id: "lubinChojnow",
    name: "Lubin - Chojnów",
    stops: [
      "LUBIN Ustronie",
      "LUBIN ul. Jana Pawła II Gwarków",
      "LUBIN ul. Hutnicza Pod Kładką",
      "LUBIN ul. Niepodległości Centrum",
      "KRZECZYN WIELKI",
      "KRZECZYN WIELKI SZKOŁA",
      "GORZYCA",
      "OGRODZISKA Posesja nr 6",
      "ŻABICE Posesja nr 4a n/ż",
      "ŻABICE Posesja nr 35 wieś",
      "MICHAŁÓW SKRZYŻOWANIE",
      "JAROSZÓWKA I",
      "JAROSZÓWKA II",
      "ZAMIENICE Kościół",
      "ZAMIENICE Stawy",
      "ROKITKI Poczta",
      "ROKITKI",
      "ROKITKI Kolonia",
      "CZERNIKOWICE",
      "CHOJNÓW Pl. Dworcowy Przyst. dworcowy",
    ],
    schedules: [
      {
        times: [
          "05:54",
          "05:55",
          "05:56",
          "06:00",
          "06:06",
          "06:09",
          "06:12",
          "06:16",
          "06:17",
          "06:19",
          "06:22",
          "06:26",
          "06:29",
          "06:32",
          "06:36",
          "06:38",
          "06:40",
          "06:43",
          "06:46",
          "06:51",
        ],
        limitations: ["F", "D"],
      },
      {
        times: [
          "08:14",
          "08:15",
          "08:16",
          "08:20",
          "08:26",
          "08:29",
          "08:32",
          "08:36",
          "08:37",
          "08:39",
          "08:42",
          "08:46",
          "08:49",
          "08:52",
          "08:56",
          "08:58",
          "09:00",
          "09:03",
          "09:06",
          "09:11",
        ],
        limitations: ["F", "D"],
      },
      {
        times: [
          "10:52",
          "10:53",
          "10:54",
          "10:58",
          "11:04",
          "11:07",
          "11:10",
          "11:14",
          "11:15",
          "11:17",
          "11:20",
          "11:24",
          "11:27",
          "11:30",
          "11:34",
          "11:36",
          "11:38",
          "11:41",
          "11:44",
          "11:49",
        ],
        limitations: ["F", "D"],
      },
      {
        times: [
          "13:58",
          "13:59",
          "14:00",
          "14:04",
          "14:10",
          "14:13",
          "14:16",
          "14:20",
          "14:21",
          "14:23",
          "14:26",
          "14:30",
          "14:33",
          "14:36",
          "14:40",
          "14:42",
          "14:44",
          "14:47",
          "14:50",
          "14:55",
        ],
        limitations: ["F", "D"],
      },
      {
        times: [
          "-",
          "-",
          "-",
          "16:05",
          "16:11",
          "16:14",
          "16:17",
          "16:21",
          "16:22",
          "16:24",
          "16:27",
          "16:31",
          "16:34",
          "16:37",
          "16:41",
          "16:43",
          "16:45",
          "16:48",
          "16:51",
          "16:56",
        ],
        limitations: ["F", "D"],
      },
    ],
    limitations: {
      F: "Kursuje od poniedziałku do piątku",
      D: "Nie kursuje 24-26 XII, w Wielką Sobotę i Święta Wielkanocne",
    },
  },
  {
    id: "chojnowLubin",
    name: "Chojnów - Lubin",
    stops: [
      "CHOJNÓW Pl. Dworcowy Przyst. dworcowy",
      "CZERNIKOWICE",
      "ROKITKI Kolonia",
      "ROKITKI",
      "ROKITKI Poczta",
      "ZAMIENICE Stawy",
      "ZAMIENICE Kościół",
      "JAROSZÓWKA II",
      "JAROSZÓWKA I",
      "MICHAŁÓW SKRZYŻOWANIE",
      "ŻABICE Posesja nr 35 wieś",
      "ŻABICE Posesja nr 4a n/ż",
      "OGRODZISKA Posesja nr 6",
      "GORZYCA",
      "KRZECZYN WIELKI Szkoła",
      "KRZECZYN WIELKI",
      "LUBIN ul. Skłodowskiej szkoła",
      "LUBIN ul. Hutnicza Pod Kładką",
      "LUBIN ul. Jana Pawła II Gwarków",
      "LUBIN Ustronie",
    ],
    schedules: [
      {
        times: [
          "6:55",
          "7:00",
          "7:03",
          "7:06",
          "7:08",
          "7:10",
          "7:14",
          "7:17",
          "7:20",
          "7:24",
          "7:27",
          "7:29",
          "7:30",
          "7:34",
          "7:37",
          "7:40",
          "7:46",
          "7:50",
          "7:51",
          "7:52",
        ],
        limitations: ["F", "D"],
      },
      {
        times: [
          "9:20",
          "9:25",
          "9:28",
          "9:31",
          "9:33",
          "9:35",
          "9:39",
          "9:42",
          "9:45",
          "9:49",
          "9:52",
          "9:54",
          "9:55",
          "9:59",
          "10:02",
          "10:05",
          "10:11",
          "10:15",
          "10:16",
          "10:17",
        ],
        limitations: ["F", "D"],
      },
      {
        times: [
          "13:00",
          "13:05",
          "13:08",
          "13:11",
          "13:13",
          "13:15",
          "13:19",
          "13:22",
          "13:25",
          "13:29",
          "13:32",
          "13:34",
          "13:35",
          "13:39",
          "13:42",
          "13:45",
          "13:51",
          "13:55",
          "13:56",
          "13:57",
        ],
        limitations: ["F", "D"],
      },
      {
        times: [
          "15:10",
          "15:15",
          "15:18",
          "15:21",
          "15:23",
          "15:25",
          "15:29",
          "15:32",
          "15:35",
          "15:39",
          "15:42",
          "15:44",
          "15:45",
          "15:49",
          "15:52",
          "15:55",
          "16:01",
          "-",
          "-",
          "-",
        ],
        limitations: ["F", "D"],
      },
      {
        times: [
          "17:00",
          "17:05",
          "17:08",
          "17:11",
          "17:13",
          "17:15",
          "17:19",
          "17:22",
          "17:25",
          "17:29",
          "17:32",
          "17:34",
          "17:35",
          "17:39",
          "17:42",
          "17:45",
          "17:51",
          "17:55",
          "17:56",
          "17:57",
        ],
        limitations: ["F", "D"],
      },
    ],
    limitations: {
      F: "Kursuje od poniedziałku do piątku",
      D: "Nie kursuje 24-26 XII, w Wielką Sobotę i Święta Wielkanocne",
    },
  },
  {
    id: "lubinJawor",
    name: "Lubin - Jawor (przez Legnicę)",
    stops: [
      "LUBIN Ustronie",
      "LUBIN Jana Pawła II / Gwarków",
      "LUBIN Jana Pawła II / Cisowa",
      "LUBIN Al. Niepodległości - Centrum",
      "LUBIN ul. Paderewskiego - Rondo",
      "LUBIN KEN - Kładka",
      "LUBIN Przejście Podziemne",
      "LUBIN ul. Legnicka",
      "LUBIN ul. Energetyka",
      "Chróstnik",
      "Gorzelin n/ż",
      "Karczowiska",
      "Kochlice II",
      "Kochlice IV",
      "Rzeszotary",
      "LEGNICA ul. Poznańska - Struga",
      "LEGNICA ul. Dworcowa PKP",
      "LEGNICA ul. Witelona – Pl.Wilsona",
      "LEGNICA ul. Skarbka – Plac Słowiański",
      "LEGNICA ul. Jaworzyńska – Grabskiego",
      "LEGNICA ul. Jaworzyńska – Nowodworska",
      "LEGNICA ul. Jaworzyńska – LSSE",
      "Nowa Wieś Legnicka",
      "Koiszków",
      "Mąkolice",
      "Małuszów",
      "JAWOR ul. Krzywoustego – Basen Kryty",
      "JAWOR ul. Starojaworska Osiedle/Basen",
      "JAWOR ul. Starojaworska Kościół - Garaże ",
      "JAWOR ul. Rapackiego CZAR",
      "JAWOR ul. Rapackiego Liceum Medyczne",
      "JAWOR ul. Wrocławska Zespół Szkół Zawodowych",
      "JAWOR ul. Armii Krajowej – Ośrodek Zdrowia",
      "JAWOR ul. Chopina – Przedszkole",
      "JAWOR Podzamcze – Targowisko",
    ],
    schedules: [
      {
        times: [
          "04:25",
          "04:26",
          "04:27",
          "04:35",
          "04:37",
          "04:38",
          "04:39",
          "04:40",
          "04:41",
          "04:45",
          "04:48",
          "04:49",
          "04:50",
          "04:51",
          "04:55",
          "04:59",
          "05:01",
          "05:02",
          "05:03",
          "05:05",
          "05:12",
          "05:14",
          "05:17",
          "05:20",
          "05:22",
          "05:24",
          "05:31",
          "05:32",
          "05:33",
          "05:34",
          "05:35",
          "05:36",
          "05:37",
          "05:38",
          "05:39",
        ],
        limitations: ["E"],
      },
      {
        times: [
          "06:55",
          "06:56",
          "06:57",
          "07:05",
          "07:07",
          "07:08",
          "07:09",
          "07:10",
          "07:11",
          "07:15",
          "07:18",
          "07:19",
          "07:20",
          "07:21",
          "07:25",
          "07:29",
          "07:31",
          "07:32",
          "07:33",
          "07:35",
          "07:42",
          "07:44",
          "07:47",
          "07:50",
          "07:52",
          "07:54",
          "08:01",
          "08:02",
          "08:03",
          "08:04",
          "08:05",
          "08:06",
          "08:07",
          "08:08",
          "08:09",
        ],
        limitations: ["E"],
      },
      {
        times: [
          "09:25",
          "09:26",
          "09:27",
          "09:35",
          "09:37",
          "09:38",
          "09:39",
          "09:40",
          "09:41",
          "09:45",
          "09:48",
          "09:49",
          "09:50",
          "09:51",
          "09:55",
          "09:59",
          "10:01",
          "10:02",
          "10:03",
          "10:05",
          "10:12",
          "10:14",
          "10:17",
          "10:20",
          "10:22",
          "10:24",
          "10:31",
          "10:32",
          "10:33",
          "10:34",
          "10:35",
          "10:36",
          "10:37",
          "10:38",
          "10:39",
        ],
        limitations: ["E"],
      },
      {
        times: [
          "12:12",
          "12:13",
          "12:14",
          "12:22",
          "12:24",
          "12:25",
          "12:26",
          "12:27",
          "12:28",
          "12:32",
          "12:35",
          "12:36",
          "12:37",
          "12:38",
          "12:42",
          "12:46",
          "12:48",
          "12:49",
          "12:50",
          "12:52",
          "12:59",
          "13:01",
          "13:04",
          "13:07",
          "13:09",
          "13:11",
          "13:18",
          "13:19",
          "13:20",
          "13:21",
          "13:22",
          "13:23",
          "13:24",
          "13:25",
          "13:26",
        ],
        limitations: ["E"],
      },
      {
        times: [
          "15:46",
          "15:47",
          "15:48",
          "15:56",
          "15:58",
          "15:59",
          "16:00",
          "16:01",
          "16:02",
          "16:06",
          "16:09",
          "16:10",
          "16:11",
          "16:12",
          "16:16",
          "16:20",
          "16:22",
          "16:23",
          "16:24",
          "16:26",
          "16:33",
          "16:35",
          "16:38",
          "16:41",
          "16:43",
          "16:45",
          "16:52",
          "16:53",
          "16:54",
          "16:55",
          "16:56",
          "16:57",
          "16:58",
          "16:59",
          "17:00",
        ],
        limitations: ["E"],
      },
    ],
    limitations: {
      E: "Kursuje od poniedziałku do soboty oprócz świąt",
      D: "Kursuje od poniedziałku do piątku oprócz świąt",
    },
  },
  {
    id: "jaworLubin",
    name: "Jawor - Lubin (przez Legnicę)",
    stops: [
      "JAWOR Podzamcze – Targowisko",
      "JAWOR ul. Chopina – Przedszkole",
      "JAWOR ul. Armii Krajowej – Ośrodek Zdrowia",
      "JAWOR ul. Wrocławska Zespół Szkół Zawodowych",
      "JAWOR ul. Rapackiego Liceum Medyczne",
      "JAWOR ul. Rapackiego CZAR",
      "JAWOR ul. Starojaworska Kościół - Garaże ",
      "JAWOR ul. Starojaworska Osiedle/Basen",
      "JAWOR ul. Krzywoustego – Basen Kryty",
      "Małuszów",
      "Mąkolice",
      "Koiszków",
      "Nowa Wieś Legnicka",
      "LEGNICA ul. Jaworzyńska - Gniewomierska",
      "LEGNICA ul. Jaworzyńska – Nowodworska",
      "LEGNICA ul. Jaworzyńska – Tatarska",
      "LEGNICA ul. Skarbka – Mickiewicza",
      "LEGNICA ul. Libana – Kartuska",
      "LEGNICA ul. Pocztowa PKS",
      "LEGNICA ul. Poznańska - Rzeszotarska",
      "Rzeszotary",
      "Kochlice III",
      "Kochlice I",
      "Karczowiska",
      "Gorzelin n/ż",
      "Chróstnik",
      "LUBIN ul. Energetyka",
      "LUBIN ul. Legnicka",
      "LUBIN Przejście Podziemne",
      "LUBIN KEN - Kładka",
      "LUBIN ul. Paderewskiego - Rondo",
      "LUBIN Al. Niepodległości - Centrum",
      "LUBIN Jana Pawła II / Cisowa",
      "LUBIN Jana Pawła II / Gwarków",
      "LUBIN Ustronie",
    ],
    schedules: [
      {
        times: [
          "05:40",
          "05:41",
          "05:42",
          "05:43",
          "05:44",
          "05:45",
          "05:46",
          "05:47",
          "05:48",
          "05:55",
          "05:57",
          "05:59",
          "06:02",
          "06:05",
          "06:07",
          "06:11",
          "06:14",
          "06:15",
          "06:16",
          "06:17",
          "06:23",
          "06:26",
          "06:27",
          "06:29",
          "06:32",
          "06:33",
          "06:37",
          "06:38",
          "06:39",
          "06:40",
          "06:41",
          "06:43",
          "06:47",
          "06:48",
          "06:49",
        ],
        limitations: ["E"],
      },
      {
        times: [
          "08:10",
          "08:11",
          "08:12",
          "08:13",
          "08:14",
          "08:15",
          "08:16",
          "08:17",
          "08:18",
          "08:25",
          "08:27",
          "08:29",
          "08:32",
          "08:35",
          "08:37",
          "08:44",
          "08:46",
          "08:47",
          "08:49",
          "08:51",
          "08:55",
          "08:59",
          "09:00",
          "09:01",
          "09:02",
          "09:05",
          "09:09",
          "09:10",
          "09:11",
          "09:12",
          "09:13",
          "09:15",
          "09:23",
          "09:24",
          "09:25",
        ],
        limitations: ["E"],
      },
      {
        times: [
          "10:45",
          "10:46",
          "10:47",
          "10:48",
          "10:49",
          "10:50",
          "10:51",
          "10:52",
          "10:53",
          "11:00",
          "11:02",
          "11:04",
          "11:07",
          "11:10",
          "11:12",
          "11:19",
          "11:21",
          "11:22",
          "11:24",
          "11:26",
          "11:30",
          "11:34",
          "11:35",
          "11:36",
          "11:37",
          "11:40",
          "11:44",
          "11:45",
          "11:46",
          "11:47",
          "11:48",
          "11:50",
          "11:58",
          "11:59",
          "12:00",
        ],
        limitations: ["E"],
      },
      {
        times: [
          "14:21",
          "14:23",
          "14:24",
          "14:25",
          "14:26",
          "14:27",
          "14:28",
          "14:29",
          "14:30",
          "14:39",
          "14:41",
          "14:43",
          "14:46",
          "14:49",
          "14:51",
          "14:59",
          "15:05",
          "15:08",
          "15:10",
          "15:12",
          "15:15",
          "15:18",
          "15:19",
          "15:21",
          "15:23",
          "15:26",
          "15:29",
          "15:30",
          "15:31",
          "15:32",
          "15:34",
          "15:37",
          "15:41",
          "15:42",
          "15:43",
        ],
        limitations: ["E"],
      },
      {
        times: [
          "17:30",
          "17:31",
          "17:32",
          "17:33",
          "17:34",
          "17:35",
          "17:36",
          "17:37",
          "17:38",
          "17:45",
          "17:47",
          "17:49",
          "17:52",
          "17:55",
          "17:57",
          "18:04",
          "18:06",
          "18:07",
          "18:09",
          "18:11",
          "18:15",
          "18:19",
          "18:20",
          "18:21",
          "18:22",
          "18:25",
          "18:29",
          "18:30",
          "18:31",
          "18:32",
          "18:33",
          "18:35",
          "18:43",
          "18:44",
          "18:45",
        ],
        limitations: ["E"],
      },
    ],
    limitations: {
      E: "Kursuje od poniedziałku do soboty oprócz świąt",
      D: "Kursuje od poniedziałku do piątku oprócz świąt",
    },
  },
];
