import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.scss"]
})
export class GalleryComponent implements OnInit {
  images: number[][];
  toShow: {
    show: boolean;
    image: number;
  };

  constructor() {}

  ngOnInit() {
    this.toShow = { show: false, image: 1 };
    this.images = [
      [1, 4],
      [5, 6],
      [7, 8],
      [9, 10],
      [11, 12],
      [13, 14],
      [15, 16],
      [17, 18],
      [19, 20],
      [21, 22],
      [23, 30],
      [25, 26],
      [27, 29]
    ];
  }

  show(image: number) {
    this.toShow = { show: true, image: image };
  }
}
