<section class="home-container">
  <section class="home-picker">
    <div class="home-picker-content desktop">
      <p>Znajdź połączenie</p>
      <app-picker></app-picker>
    </div>
    <article class="home-picker-content mobile">
      <p (click)="showPicker = !showPicker">
        Znajdź połączenie
        <span class="material-icons">
          {{ showPicker ? "keyboard_arrow_up" : "keyboard_arrow_down" }}
        </span>
      </p>
      <ng-container *ngIf="showPicker">
        <app-picker></app-picker>
      </ng-container>
    </article>
  </section>

  <section class="home-carousel" id="cf">
    <img class="bottom" src="/assets/photos/22.jpg" />
    <img class="top" src="/assets/photos/23.jpg" />
  </section>

  <section class="home-notes">
    <article [innerHTML]="notes[currentNote]"></article>
    <article class="home-notes-arrow">
      <span class="material-icons" (click)="nextNote()">
        keyboard_arrow_right
      </span>
    </article>
  </section>
</section>

<div class="corona-overlay" *ngIf="showPopup">
  <div class="corona-content">
    <div class="close-icon" (click)="showPopup = false">
      <i class="material-icons"> close </i>
    </div>
    <h1>UWAGA Drodzy Pasażerowie !!!</h1>
    <p>
      W związku z rozwijającą się sytuacją epidemiologiczną w naszym kraju oraz
      w trosce o Wasze i nasze zdrowie , informujemy, że z dniem 01.11.2020
      firma Mistral zawiesza kursowanie busów na linii Lubin-Jawor do odwołania.
    </p>
    <p>
      Tym samym informujemy, że busy na linii Lubin- Chojnów będą kursować bez
      zmian według rozkładu jazdy dostępnego na przystankach oraz na naszej
      stronie internetowej.
    </p>
    <p>
      O wszelkich zmianach będziemy Was informować na bieżąco za pośrednictwem
      naszego Facebooka.
    </p>
    <p>Prosimy o wyrozumiałość i zrozumienie, życzymy zdrowia i spokoju.</p>
    <p>Dbajcie o siebie i #zostańwdomu !</p>
  </div>
</div>
