import { Component, OnInit } from "@angular/core";

import { SCHEDULE } from "../schedule";
import { RouteInterface } from "../route.interface";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"]
})
export class ListComponent implements OnInit {
  routes: RouteInterface[] = SCHEDULE;
  selectedRoute: RouteInterface = null;
  selectedStop: string = null;
  nextStops: string[] = [];
  selectedStopDepartures: {
    time: string;
    limitations: string[];
  }[] = [];
  selectedStopLimitations: string[] = [];

  constructor() {}

  ngOnInit() {}

  selectStop(index: number) {
    this.selectedStop = this.selectedRoute.stops[index];
    this.nextStops = this.selectedRoute.stops.slice(index);
    this.selectedStopDepartures = this.selectedRoute.schedules.map(s => {
      return {
        time: s.times[index],
        limitations: s.limitations
      };
    });

    this.selectedStopDepartures.forEach(d => {
      d.limitations.forEach(l => {
        if (this.selectedStopLimitations.indexOf(l) === -1) {
          this.selectedStopLimitations.push(l);
        }
      });
    });

    this.selectedStopLimitations = this.selectedStopLimitations.map(
      l => `${l} - ${this.selectedRoute.limitations[l]}`
    );
  }

  clear() {
    this.selectedStop = null;
    this.selectedStopLimitations = [];
    this.selectedStopDepartures = [];
    this.nextStops = [];
  }
}
