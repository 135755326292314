<section class="contact-container">
  <div id="fb-root"></div>
  <script>
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src =
        "//connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v2.6&appId=1504825226482184";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  </script>

  <section class="map">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11640.401482986375!2d16.20104166588426!3d51.38711862545248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTHCsDIzJzA0LjAiTiAxNsKwMTEnNDkuMyJF!5e0!3m2!1sen!2sdk!4v1466022603247"
      width="100%"
      height="400"
      frameborder="0"
      style="border: 0;"
    ></iframe>
  </section>
  <section>
    <div class="container">
      <div class="contact">
        <h3>Zamów usługę</h3>
        <form [formGroup]="longForm" (ngSubmit)="submitLongForm()">
          <mat-form-field color="accent">
            <input
              matInput
              placeholder="Imię i nazwisko"
              formControlName="name"
            />
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Firma" formControlName="company" />
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Email" formControlName="email" />
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              placeholder="Numer telefonu"
              formControlName="telephone"
            />
          </mat-form-field>

          <div class="dates">
            <mat-form-field>
              <input
                matInput
                [matDatepicker]="from"
                placeholder="Odjazd"
                formControlName="from"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="from"
              ></mat-datepicker-toggle>
              <mat-datepicker #from></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                [matDatepicker]="to"
                placeholder="Powrót"
                formControlName="to"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="to"
              ></mat-datepicker-toggle>
              <mat-datepicker #to></mat-datepicker>
            </mat-form-field>
          </div>

          <mat-form-field>
            <input matInput placeholder="Z" formControlName="where" />
          </mat-form-field>

          <div class="people">
            <mat-form-field class="responsible">
              <input
                matInput
                placeholder="Osoba odpowiedzialna"
                formControlName="responsible"
              />
            </mat-form-field>

            <mat-form-field class="amount">
              <input
                matInput
                placeholder="Liczba osób"
                formControlName="number"
              />
            </mat-form-field>
          </div>

          <mat-form-field>
            <textarea
              matInput
              placeholder="Miejsce docelowe i plan wycieczki"
              formControlName="text"
            ></textarea>
          </mat-form-field>
          <button type="submit">Zamów</button>
        </form>
      </div>
      <div class="share">
        <h3>Adres</h3>
        <article class="address">
          <p>
            <i class="material-icons">
              map
            </i>
            P.P.H.U. "Al-L" Mistral
          </p>
          <p>ul. Legnicka 65</p>
          <p>
            59-300 Lubin
          </p>
          <p>
            (salon samochodowy OPEL MM Cars)
          </p>
        </article>
        <h3 class="title">Info</h3>
        <p>
          <i class="material-icons">
            local_phone
          </i>
          703-303-296
        </p>
        <p>
          <i class="material-icons">
            email
          </i>
          mistral-bus@wp.pl
        </p>
        <a
          href="https://www.facebook.com/Mistral-Przewozy-637865786308919/"
          target="_blank"
        >
          <p>facebook</p>
        </a>
        <div
          class="fb-share-button"
          data-href="https://www.facebook.com/Mistral-Przewozy-637865786308919/"
          data-layout="button"
          data-size="small"
          data-mobile-iframe="true"
        >
          <a
            class="fb-xfbml-parse-ignore"
            target="_blank"
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.facebook.com%2FMistral-Przewozy-637865786308919%2F&amp;src=sdkpreparse"
            >Share</a
          >
        </div>
        <div
          class="fb-like"
          data-href="https://www.facebook.com/Mistral-Przewozy-637865786308919/"
          data-layout="button_count"
          data-action="like"
          data-size="small"
          data-show-faces="true"
          data-share="false"
        ></div>
      </div>
      <div class="questions">
        <h3>Skontaktuj się z nami</h3>
        <form [formGroup]="questions" (ngSubmit)="submitQuestions()">
          <mat-form-field>
            <input matInput placeholder="Imię" formControlName="name" />
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Nazwisko" formControlName="surname" />
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Email" formControlName="email" />
          </mat-form-field>

          <mat-form-field>
            <textarea
              matInput
              placeholder="Napisz do nas"
              formControlName="text"
            ></textarea>
          </mat-form-field>
          <button type="submit">
            Wyślij
          </button>
        </form>
      </div>
    </div>
  </section>
</section>
