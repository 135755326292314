<div class="list-container">
  <mat-form-field>
    <mat-select [(ngModel)]="selectedRoute" placeholder="Wybierz">
      <mat-option *ngFor="let route of routes" [value]="route">
        {{ route.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="selectedRoute">
    <div>
      <button
        *ngFor="let stop of selectedRoute.stops; index as i"
        (click)="selectStop(i)"
      >
        {{ stop }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedStop">
    <div class="list-overlay">
      <div class="list-overlay-content">
        <div class="list-overlay-header">
          <h4>{{ selectedStop }}</h4>
        </div>
        <div class="list-overlay-body">
          <div>
            <h4>Bus zatrzymuje się na przystankach:</h4>
            <p *ngFor="let stop of nextStops">{{ stop }}</p>
          </div>
          <div>
            <h4>Bus odjeżdża o godzinie:</h4>
            <p *ngFor="let departure of selectedStopDepartures">
              {{ departure.time }} - {{ departure.limitations.join(" ") }}
            </p>
          </div>
        </div>
        <div class="list-overlay-details">
          <p *ngFor="let limitation of selectedStopLimitations">
            {{ limitation }}
          </p>
        </div>
        <div class="list-overlay-footer">
          <button (click)="clear()">Zamknij</button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
