import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  showPopup = true;
  showPicker = false;
  notes = [
    ` <h1 class="bold">Polityka Prywatności</h1>
        <p>
        W dniu 25.05.2018 weszło w życie Ogólne rozporządzenie o ochronie danych
      (GDPR, RODO). Zapoznaj się z naszą zaktualizowaną
      <a href="privacy">Polityką Prywatności</a>.
    </p>`,
    `<h1 class="bold">Uwaga - zmiany w rozkładach jazdy</h1><p>Drodzy pasażerowie, informujemy, że wprowadzone zostały zmiany w rozkładach jazdy na liniach: <b>Lubin - Chojnów
                    - Lubin</b> oraz <b>Lubin - Jawor - Lubin</b>. Uprzejmie prosimy o zapoznanie się z nowymi rozkładami na
                naszej stronie.</p>`,
    `<h1 class="bold">O nas</h1>
    <p>
      Firma Mistral oferuje przewozy osób na terenie kraju oraz za granicą.
      Obsługujemy także linie regularne:
    </p>
    <ul>
      <li>
        Lubin - Chojnów
      </li>
      <li>
        Lubin - Jawor (przez Legnicę).
      </li>
    </ul>
    <p>
      Do dyspozycji klienta posiadamy pojazdy 17-, 19-, 27-, 48- i 58-osobowe.
      Zapraszamy!
    </p>`,
    `<h1 class="bold">Oferta pracy</h1>
    <p>
      Poszukujemy kierowców z Lubina i okolic, posiadających prawo jazdy kat.D.
      Chętnych prosimy o kontakt telefoniczny (603 758 230) lub mailowy
      (mistral-bus@wp.pl).
    </p>`,
    `<h1 class="bold">Komfort</h1>
    <p>
      W naszej flocie znajduje się busy Marcedesy Sprintery które przewożonym
      osobom zapewniają najwyższy komfort podróży. Posiadają wygodne profilowane
      i rozsuwane fotele, niektóre busy posiadają klimatyzacje, aby w ciepłe dni
      zapewnić naszym pasażerom ochłodę w czasie podróży.
    </p>`,
    `<h1 class="bold">Bezpieczeństwo</h1>
    <p>
      Stawiamy przede wszystkim na bezpieczeństwo podróżujących, dlatego nasze
      pojazdy spełniają wszelkie wymogi, zarówno pod względem technicznym,
      samochody są regularnie sprawdzane i serwisowane. Nasi kierowcy to
      doświadczeni ludzie, mający na koncie tysięcy przejechanych kilometrów.
      Wszyscy kierowcy posiadają opowiednią licencję, uprawniającą ich do
      przewozu osób. Wykorzystując wiedzę zdobytą na przełomie wielu lat swojej
      działalności robimy wszystko aby nasi klienci czuli się pewnie i
      bezpiecznie.
    </p>`,
    `<h1 class="bold">Troska o klienta</h1>
    <p>
      Przykładamy ogromną uwagę do troski o naszych klientów. Klient jest dla
      nas najważniejszy, dlatego też może liczyć na naszą pomoc podczas
      realizacji zamówienia. Służymy pomocą i odpowiemy chętnie na każde
      pytanie. Każdego klienta traktujemy indywidualnie, ponieważ mimo tak
      wąskiej branży jak transport osób, potrzeby naszych klientów są różne.
    </p>`,
    `<h1 class="bold">Niskie koszty przejazdu</h1>
    <p>
      Każdego dnia robimy wszystko aby usprawnić naszą pracę i obniżyć koszty,
      które musi ponosić klient. W naszej branży najważniejsza jest flota
      pojazdów, którą wykorzystujemy do przewozu osób. Dzięki inwestycji w
      najwyższej klasy nowe samochody marki Mercedes, koszty eksploatacji
      znacząco spadły. Dzięki temu koszty, które płącą nasi klienci są również
      najniższe na rynku. Korzystając z naszych usług otrzymujecie więc
      najwyższą jakość w najniższej cenie. Dodatkowo klientom korzystającym
      regularnie z naszych usług proponujemy atrakcyjne rabaty. Dla młodzieży
      uczącej się proponujemy bilety miesięczne.
    </p>`,
  ];

  currentNote = 0;

  constructor() {}

  ngOnInit(): void {}

  nextNote() {
    if (this.currentNote === this.notes.length - 1) {
      this.currentNote = 0;
    } else {
      this.currentNote += 1;
    }
  }
}
