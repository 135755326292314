// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyB4CuVrgEy-H1JDTi_WCCQ0BneV8D3I_CM",
    authDomain: "mistral-lubin-b7491.firebaseapp.com",
    databaseURL: "https://mistral-lubin-b7491.firebaseio.com",
    projectId: "mistral-lubin-b7491",
    storageBucket: "mistral-lubin-b7491.appspot.com",
    messagingSenderId: "769865967853",
    appId: "1:769865967853:web:bbb54e8fcbb4e0b59f04e4",
    measurementId: "G-9JBLF3TYCR",
  },
  email: "lu.mangano@gmail.com",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
