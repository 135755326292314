<section class="topbar">
  <article class="topbar-image">
    <a routerLink="/">
      <img src="../../../assets/logo.png" />
    </a>
  </article>
  <nav class="topbar-navigation">
    <a [class.active]="current === 'home'" (click)="goTo('home')">Home</a>
    <a [class.active]="current === 'schedule'" (click)="goTo('schedule')">
      Rozkład jazdy
    </a>
    <a [class.active]="current === 'gallery'" (click)="goTo('gallery')">
      Galeria</a
    >
    <a [class.active]="current === 'contact'" (click)="goTo('contact')">
      Kontakt
    </a>
  </nav>
</section>
