<section class="picker-container">
  <mat-form-field>
    <mat-select
      [(ngModel)]="from"
      placeholder="Z"
      (selectionChange)="updateTo()"
    >
      <ng-container *ngFor="let route of routes">
        <mat-option disabled>{{ route.name }}</mat-option>
        <ng-container *ngFor="let stop of route.stops">
          <mat-option [value]="{ route: route, stop: stop }">{{
            stop
          }}</mat-option>
        </ng-container>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-select [(ngModel)]="to" placeholder="Do">
      <ng-container *ngFor="let stop of availableTo">
        <mat-option [value]="stop">{{ stop }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <div class="ngx-timepicker-field-example">
    <ngx-timepicker-field
      [format]="24"
      [defaultTime]="now"
      (timeChanged)="changedTime($event)"
    ></ngx-timepicker-field>
  </div>

  <mat-form-field>
    <input
      matInput
      [matDatepicker]="date"
      [min]="minDate"
      [max]="maxDate"
      disabled
      [value]="minDate"
      (dateChange)="changedDate($event)"
    />
    <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
    <mat-datepicker #date disabled="false"></mat-datepicker>
  </mat-form-field>

  <button (click)="search()">
    Szukaj
  </button>
</section>

<ng-container *ngIf="showResult">
  <div class="list-overlay">
    <div class="list-overlay-content">
      <div class="list-overlay-header">
        <h4>Szczegóły twojego przejazdu</h4>
      </div>
      <div
        class="list-overlay-body"
        *ngIf="departureTimes.length > 0; else noResults"
      >
        <div>
          <h4>Trasa</h4>
          <p>{{ from.route.name }}</p>
        </div>
        <div>
          <h4>Czas odjazdu</h4>
          <p *ngFor="let dep of departureTimes">{{ dep }}</p>
        </div>
        <div>
          <h4>Czas przyjazdu</h4>
          <p *ngFor="let arr of arrivalTimes">{{ arr }}</p>
        </div>
      </div>
      <ng-template #noResults>
        <div class="list-overlay-body-no-results">
          <h4>Spróbuj wyszukać połączenie o innym czasie</h4>
        </div>
      </ng-template>
      <div class="list-overlay-details"></div>
      <div class="list-overlay-footer">
        <button (click)="clear()">Zamknij</button>
      </div>
    </div>
  </div>
</ng-container>
