import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { ContactComponent } from "./contact/contact.component";
import { ScheduleComponent } from "./schedule/schedule.component";
import { PrivacyComponent } from "./privacy/privacy.component";

const routes: Routes = [
  {
    path: "home",
    component: HomeComponent,
  },
  {
    path: "gallery",
    component: GalleryComponent,
  },
  {
    path: "schedule",
    component: ScheduleComponent,
  },
  {
    path: "contact",
    component: ContactComponent,
  },
  {
    path: "privacy",
    component: PrivacyComponent,
  },
  {
    path: "**",
    redirectTo: "home",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
