<section class="gallery-container" *ngFor="let index of images">
  <img (click)="show(index[0])" src="../../assets/photos/{{ index[0] }}.jpg" />
  <img (click)="show(index[1])" src="../../assets/photos/{{ index[1] }}.jpg" />
</section>

<div *ngIf="toShow.show" class="overlay">
  <div (click)="toShow.show = false" class="close-button">
    <i class="material-icons">
      close
    </i>
  </div>
  <img src="../../assets/photos/{{ toShow.image }}.jpg" />
</div>
