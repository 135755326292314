<section class="schedule-container">
  <article class="schedule-picker">
    <h3>Znajdź połączenie</h3>
    <app-picker></app-picker>
  </article>
  <article class="schedule-list">
    <h3>Sprawdź rozkład jazdy z wybranego przystanku</h3>
    <app-list></app-list>
  </article>
</section>
