import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import { HttpClientModule } from "@angular/common/http";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { TopbarComponent } from "./navigation/topbar/topbar.component";
import { FooterComponent } from "./navigation/footer/footer.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { ContactComponent } from "./contact/contact.component";
import { PickerComponent } from "./schedule/picker/picker.component";
import { ScheduleComponent } from "./schedule/schedule.component";
import { ListComponent } from "./schedule/list/list.component";
import { DatePipe } from "@angular/common";
import { PrivacyComponent } from "./privacy/privacy.component";
import { environment } from "src/environments/environment";
import { MatSnackBarModule } from "@angular/material/snack-bar";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TopbarComponent,
    FooterComponent,
    GalleryComponent,
    ContactComponent,
    PickerComponent,
    ScheduleComponent,
    ListComponent,
    PrivacyComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSnackBarModule,
    NgxMaterialTimepickerModule.setLocale("pl-PL"),
    AngularFireModule.initializeApp(environment.firebase),
    HttpClientModule,
    AngularFireAuthModule,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "pl-PL" }, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
